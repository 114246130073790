.screen {
  width: 900px;
  height: 700px;
  background-color: #377e7f;
}

.icons {
  width: 80px;
  margin: 8px 0;
  position: fixed;
}

.iconContainer {
  overflow: hidden;
  /* display: flex; */
  font-size: 16px;
  color: white;
  width: 100%;
  padding: 8px 0;
}

.iconContainer > img {
  display: block;
  margin: 0 auto;
  width: 48px;
  height: 48px;
  padding-bottom: 8px;
}

.sli {
  height: 200px;
  /* flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.sli-container {
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.windowContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
