.window {
  position: relative;

  border: 2px solid black;
  box-shadow: 1px 1px 3px black;
  background-color: white;
}

.window > .title {
  display: flex;
  align-items: center;
  height: 20px;
  border: 1px solid black;
  border-bottom: 2px solid black;
}

.window > .title > img {
  height: 12px;
  margin-left: 8px;
}

.dots {
  margin-top: -30px;
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots {
  bottom: 10px !important;
}

/* .dots > button {
  display: none;
} */
