@font-face {
  font-family: "IPix";
  src: local("IPix"), url(./assets/fonts/IPix.ttf) format("truetype");
}
* {
  font-family: "IPix";
}
/* 我在愁一件事情，就是这次礼物有点特殊，特殊的交付给你都费事。 */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
